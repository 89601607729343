import {Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {CampaignItemModel} from '../../../../../../models/api/campaign-item.model';
import {AssetModel} from '../../../../../../models/api/asset.model';
import {PublicationItemContentModel} from '../../../../../../models/api/publication-item-content.model';

export class LayoutDragDropItem {
    dragData: CampaignItemModel | AssetModel;
    mouseEvent: MouseEvent;
}

@Injectable()
export class CustomWorkflowLayoutService implements OnDestroy {
    private layoutUpdatedSubject = new Subject<PublicationItemContentModel[]>();
    public layoutUpdated$ = this.layoutUpdatedSubject.asObservable();

    private externalDataChangedSubject = new Subject<void>();
    public externalDataChanged$ = this.externalDataChangedSubject.asObservable();

    private addToLayoutSubject = new Subject<LayoutDragDropItem>();
    public addToLayout$ = this.addToLayoutSubject.asObservable();

    private subjects = [
        this.layoutUpdatedSubject,
        this.externalDataChangedSubject,
        this.addToLayoutSubject,
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public publicationItemContentUpdated(content: PublicationItemContentModel[]): void {
        this.layoutUpdatedSubject.next(content);
    }

    public externalDataChanged(): void {
        this.externalDataChangedSubject.next();
    }

    public addToLayout(dropEvent: LayoutDragDropItem): void {
        this.addToLayoutSubject.next(dropEvent);
    }
}
